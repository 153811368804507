import request from '@/utils/request'

export const getHomeData = (data: any) => {
  return request({
    url: '/hikCloud/docking/homepage',
    method: 'post',
    data
  })
}

export const getAttendance = (data: any) => {
  return request({
    url: '/hikCloud/docking/homepage/store/attendance',
    method: 'post',
    data
  })
}

export const getShops = (data?: any) => {
  return request({
    url: '/hikCloud/docking/store/list',
    method: 'post',
    data
  })
}

export const getRecordData = () => {
  return request({
    url: '/record/page/data',
    method: 'get'
  })
}

export const getRecordList = (data?: any) => {
  return request({
    url: '/record/List',
    method: 'post',
    data
  })
}

export const getRecordDetail = (data: string) => {
  return request({
    url: '/record/info/ById?id=' + data,
    method: 'get'
  })
}

export const getShopByName = (data: any) => {
  return request({
    url: '/hikCloud/docking/store/byName',
    method: 'post',
    data
  })
}

export const getWarning = (data: any) => {
  return request({
    url: '/hikCloud/docking/ai/warning',
    method: 'post',
    data
  })
}

export const getWarningData = (data: any) => {
  return request({
    url: '/hikCloud/docking/ai/warning/map',
    method: 'post',
    data
  })
}

export const getWarningById = (params: any) => {
  return request({
    url: '/hikCloud/docking/ai/warning/byId',
    method: 'get',
    params
  })
}

export const getAreas = () => {
  return request({
    url: '/hikCloud/docking/hdl/region',
    method: 'post'
  })
}

export const getAreaData = (data: any) => {
  return request({
    url: '/hikCloud/docking/ai/warning/map',
    method: 'post',
    data
  })
}

export const getStoreWarning = (data: any) => {
  return request({
    url: '/hikCloud/docking/store/data/warning',
    method: 'post',
    data
  })
}

export const getStoreDevice = (data: any) => {
  return request({
    url: '/hikCloud/docking/store/data/device',
    method: 'post',
    data
  })
}

export const getStoreData = (data: any) => {
  return request({
    url: '/hikCloud/docking/store/data/map',
    method: 'post',
    data
  })
}

export const getBusinessData = (data: any) => {
  return request({
    url: '/hikCloud/docking/getBusinessData',
    method: 'post',
    data
  })
}

export const getBusinessDataAll = (data: any) => {
  return request({
    url: '/hikCloud/docking/getBusinessDataAll',
    method: 'post',
    data
  })
}

export const delWarn = (id: string) => {
  return request({
    url: `/hikCloud/docking/del/warning/${id}`,
    method: 'DELETE'
  })
}

export const getMessages = (data: any) => {
  return request({
    url: '/hikCloud/docking/ai/warning/message/list',
    method: 'post',
    data
  })
}

export const getRecordActionTemplate = (data: any) => {
  return request({
    url: '/record/action/template',
    method: 'post',
    data
  })
}
export const getRecordActionTemplateDetail = (data: any) => {
  return request({
    url: '/record/action/template/detail',
    method: 'post',
    data
  })
}
export const fetchRectifyUserPage = (data: any) => {
  return request({
    url: '/record/action/fetchRectifyUserPage',
    method: 'post',
    data
  })
}
export const fetchReviewUserPage = (data: any) => {
  return request({
    url: '/record/action/fetchReviewUserPage',
    method: 'post',
    data
  })
}
export const fetchCopyUserPage = (data: any) => {
  return request({
    url: '/record/action/fetchCopyUserPage',
    method: 'post',
    data
  })
}
export const recordActionSubmit = (data: any) => {
  return request({
    url: '/record/action/submit',
    method: 'post',
    data
  })
}
